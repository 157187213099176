<template>
  <div>
    <v-alert
      dense
      outlined
      type="info"
      color="blue"
      class="mb-0 py-1 px-1 mt-8"
    >
      Os dados retornados pelo sistema são limitados a 100 linhas.
    </v-alert>

    <v-alert
      v-if="errorSql"
      dense
      outlined
      type="error"
      color="red"
      class="mb-0 py-1 px-1 mt-4 mx-4"
    >
      {{ errorSql }}
    </v-alert>

    <v-data-table
      :headers="headers"
      :items="queryData"
      :mobile-breakpoint="800"
      :loading="loadingExecQuery"
      :hide-default-header="loadingExecQuery"
      :hide-default-footer="loadingExecQuery"
      class="mt-8"
      no-data-text="Nenhum dado disponível"
    />
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    queryData: {
      type: Array,
      default: () => []
    },
    errorSql: {
      type: String,
      default: () => ''
    },
    loadingExecQuery: {
      type: Boolean,
      default: () => false
    }
  }
};
</script>
